import React from "react";
import { injectIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import iconChat from "../../assets/icon-chat.png";
import logoMedical from "../../assets/logo-medical.png";
import medicos from "../../assets/medicos.png";
import { ROUTES } from "../../utils/constants";

function Scenario1ConversationIntro({ intl }) {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const prevState = useLocation();

	return (
		<div className="h-100">
			<div className="container-fluid bg-internas">
				<div style={{ paddingTop: "6vh", paddingLeft: "6vh" }}>
					<img width={240} src={logoMedical} />
				</div>
				<div className="row d-flex justify-content-center align-items-center mt-5 pt-5">
					<div className="col-10">
						<div className="ms-5 ps-4 d-flex align-items-center">
							<img width={350} src={medicos} />
							<div className="ms-4 txtWhite txt20 text-center">
								<img width={48} src={iconChat}></img>
								<div className="bold my-3">
									Ahora que Martina ya está en tu consulta,
									vas a hablar con ella.
								</div>
								<div>Presta atención a lo que te explica:</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div
				className="container"
				style={{
					position: "absolute",
					bottom: "8vh",
				}}
			>
				<div className="d-flex justify-content-end">
					<div
						className="btn-main"
						onClick={(e) => {
							navigate(ROUTES.SCENARIO1_CONVERSATION);
						}}
					>
						<div className="text-btn-main">AVANZAR</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default injectIntl(Scenario1ConversationIntro);

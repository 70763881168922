import React, { useRef } from "react";
import { injectIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import logoMedical from "../../assets/logo-medical.png";
import reset from "../../assets/reset.png";
import mensaje1 from "../../assets/scenario5-mensaje1.png";
import mensaje2 from "../../assets/scenario5-mensaje2.png";
import mensaje3 from "../../assets/scenario5-mensaje3.png";
import mensaje4 from "../../assets/scenario5-mensaje4.png";
import mensaje5 from "../../assets/scenario5-mensaje5.png";
import mensaje6 from "../../assets/scenario5-mensaje6.png";
import mensaje7 from "../../assets/scenario5-mensaje7.png";
import mensaje8 from "../../assets/scenario5-mensaje8.png";
import { ROUTES } from "../../utils/constants";
import { modalGenericSmall } from "../../utils/logger";

function Scenario1Conversation({ intl }) {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const prevState = useLocation();

	const lastMessageRef = useRef(null);

	const scrollToBottom = () => {
		lastMessageRef?.current?.scrollIntoView({ behavior: "smooth" });
	};

	const EXPLANATION_HTML = `<div class="text-center">
			<div class="txt-black txt16">
				El <span class="bold">documento de consenso GLOSEN 2023</span>
				propone una serie de objetivos terapéuticos que se deben
				alcanzar a los 2-3 meses del inicio del tratamiento para la
				nefritis lúpica, tanto en primera línea de tratamiento como en
				recaída.
			</div>
			<div class="mt-3 txt-turquoise bold txt18">¿Conoces estos objetivos?</div>
		</div>`;

	return (
		<div className="h-100">
			<div className="container-fluid bg-internas">
				<div style={{ paddingTop: "6vh", paddingLeft: "6vh" }}>
					<img width={240} src={logoMedical} />
				</div>
				<div className="row d-flex justify-content-center align-items-center mt-5">
					<div
						className="col-9"
						style={{ height: "60vh", overflow: "scroll" }}
					>
						<div className="d-flex justify-content-start">
							<img src={mensaje1} />
						</div>
						<div className="d-flex justify-content-end">
							<img src={mensaje2} />
						</div>
						<div className="d-flex justify-content-start">
							<img src={mensaje3} />
						</div>
						<div className="d-flex justify-content-end">
							<img src={mensaje4} />
						</div>
						<div className="d-flex justify-content-start">
							<img src={mensaje5} />
						</div>
						<div className="d-flex justify-content-end">
							<img src={mensaje6} />
						</div>
						<div className="d-flex justify-content-start">
							<img src={mensaje7} />
						</div>
						<div
							className="d-flex justify-content-end"
							ref={lastMessageRef}
						>
							<img src={mensaje8} />
						</div>
					</div>
				</div>
			</div>
			<div
				className="container"
				style={{
					position: "absolute",
					right: "3vh",
					bottom: "6vh",
				}}
			>
				<div className="d-flex justify-content-end">
					<div
						className="btn-main-cancel me-3"
						onClick={(e) => scrollToBottom()}
					>
						<img height={"40%"} src={reset} />
						<div className="text-btn-main ms-2">
							Ver conservación
						</div>
					</div>
					<div
						className="btn-main"
						onClick={(e) => {
							modalGenericSmall({ html: EXPLANATION_HTML }).then(
								(res) => {
									if (res.isConfirmed)
										navigate(ROUTES.SCENARIO5_EXPLANATION);
								}
							);
						}}
					>
						<div className="text-btn-main">AVANZAR</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default injectIntl(Scenario1Conversation);

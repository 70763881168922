import { API, baseClient } from "../index";

export const getAnswerByQuizAndGroup = async (quizId, groupId) => {
	return baseClient().get(`${API}/answer/quiz/${quizId}/group/${groupId}`);
};

export const postAnswer = async (answer) => {
	console.log("answer", answer);
	return baseClient().post(`${API}/answer`, answer);
};

import { updateGroup } from "../api/group";
import { setGame, setGroup } from "../redux/actions";
import { ROUTES } from "./constants";

export function formatTime(time) {
	if (!time) return ` 0' 00"`;
	else
		return `${Math.floor(time / 60)}' ${
			time - Math.floor(time / 60) * 60
		}"`;
}

export function formatMinutes(time, timeout, finished) {
	if (time === undefined || isNaN(time) || finished) return "00";
	let remaining = (timeout - time) / 60;
	if (remaining <= 0) return "00";
	return (remaining < 10 ? "0" : "") + Math.floor(remaining);
}
export function formatSeconds(time, timeout, finished) {
	if (time === undefined || isNaN(time) || finished) return "00";
	let remaining = (timeout - time) % 60;
	if (remaining < 0) return "00";
	return (remaining < 10 ? "0" : "") + remaining;
}

export function getTimer() {
	return localStorage.getItem("total");
}

export function updateTimer(value) {
	localStorage.setItem("total", value);
}

export function clearTimer() {
	localStorage.removeItem("total");
}

export function clearItem(item) {
	localStorage.removeItem(item);
}

export function getGroupId() {
	return localStorage.getItem("groupId");
}

export function setGroupId(groupId) {
	localStorage.setItem("groupId", groupId);
}

export function clearGroup() {
	localStorage.removeItem("groupId");
}

export async function updateStats(group) {
	await updateGroup(group?._id, group);
}

export function getScenarioByScreen(path) {
	if (path?.toString()?.includes(ROUTES.SCENARIO1)) return 1;
	else if (path?.toString()?.includes(ROUTES.SCENARIO2)) return 2;
	else if (path?.toString()?.includes(ROUTES.SCENARIO3)) return 3;
	else if (path?.toString()?.includes(ROUTES.SCENARIO4)) return 4;
	else if (path?.toString()?.includes(ROUTES.SCENARIO5)) return 5;
	else if (path?.toString()?.includes(ROUTES.SCENARIO6)) return 6;
	else if (path?.toString()?.includes(ROUTES.SCENARIO7)) return 7;
	else return 8;
}

export function formatScoreTopBar(score) {
	if ((score === undefined) | isNaN(score)) return "00000";
	if (score < 0) {
		return (
			(score > -10
				? "- 0000"
				: score > -100
				? "- 000"
				: score > -1000
				? "- 00"
				: "- ") + Math.abs(score)
		);
	} else {
		return (
			(score < 10
				? "0000"
				: score < 100
				? "000"
				: score < 1000
				? "00"
				: "") + score
		);
	}
}

export function reconnect({ group, dispatch, navigate, navigateTo }) {
	dispatch(setGroup(group));
	dispatch(setGame(group?.game));
	setGroupId(group?._id);

	if (group?.lastVisited) {
		if (!group?.finished) {
			updateTimer(group?.time);
		}
		navigate(navigateTo ? navigateTo : group?.lastVisited);
	} else navigate(ROUTES.REGISTER);
}

import React, { useState } from "react";
import { injectIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { postAnswer } from "../../api/answer";
import NextButton from "../../components/NextButton";
import ScenarioBar from "../../components/ScenarioBar";
import { useTimer } from "../../hooks/useTimer";
import { updateScore, updateTime } from "../../redux/actions";
import { useLang } from "../../translations/i18n";
import { QUIZZES, ROUTES, SCENARIOS, SCORE_TYPES } from "../../utils/constants";
import {
	getScenarioByScreen,
	getTimer,
	updateStats,
} from "../../utils/helpers";
import {
	alertError,
	modalGeneric,
	modalGenericSmall,
} from "../../utils/logger";

const QUIZ_ID = QUIZZES.SCENARIO6;
const SCENARIO_ID = SCENARIOS.SCENARIO_6;
const NAVIGATE_TO = ROUTES.SCENARIO6_END;

const WRONG_ANSWER_POINTS = -10;
const MAX_WRONG_ANSWER_POINTS = -50;

function Scenario6({ intl }) {
	const lang = useLang();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const location = useLocation();
	const participant = useSelector((state) => state.group);

	const scenarioNum = getScenarioByScreen(location.pathname);

	const [points, setPoints] = useState({ penalty: 0 });
	const [time, setTime] = useState(0);

	const [isCompleted, setIsCompleted] = useState(false);

	useTimer(
		() => {
			setTime(time + 1);
		},
		isCompleted ? null : 1000
	);

	const [filters, setFilters] = useState([
		{ id: 1, text: "≥30 % de reducción", selected: true },
		{ id: 2, text: ">20 % y <30 % de reducción", selected: false },
		{ id: 3, text: "≤20 % de reducción", selected: false },
	]);

	const [options, setOptions] = useState([
		{
			id: 1,
			selected: [false, false, false],
			correct: 2,
			text: "Reducir la dosis de voclosporina en 7,9 mg (1 cápsula) 2 veces al día. Volver a hacer la prueba en dos semanas; si la disminución de la TFGe no se ha recuperado, volver a reducir la dosis en 7,9 mg (1 cápsula) 2 veces al día. ",
		},
		{
			id: 2,
			selected: [false, false, false],
			correct: 3,
			text: "Mantener la dosis actual de voclosporina y supervisar.",
		},
		{
			id: 3,
			selected: [false, false, false],
			text: "Cambiar voclosporina por otro inhibidor de la calcineurina.",
		},
		{
			id: 4,
			selected: [false, false, false],
			correct: 1,
			text: "Suspender la administración de voclosporina y reiniciar el tratamiento tras la recuperación de la TFGe con una dosis de 7,9 mg (1 cápsula) 2 veces al día y aumentar la dosis según la tolerabilidad de la función renal. ",
		},
		{
			id: 5,
			selected: [false, false, false],
			text: "Repetir la TFGe dentro de 2 semanas y reducir la dosis en 6,5-13 mg BID. ",
		},
	]);

	const SelectFilterOption = ({ id }) => {
		let filter = filters.find((x) => x.id === id);

		function handleOptionClick() {
			if (!isCompleted) return;
			let updatedFilters = filters.map((x) =>
				x.id === id
					? { ...x, selected: true }
					: { ...x, selected: false }
			);

			setFilters(updatedFilters);
		}

		function getBackgroundColor() {
			return filter?.selected ? "#FF7F6B" : "#FFFFFF";
		}

		return (
			<div>
				<div
					className={`d-flex flex-column align-items-center justify-content-center txt-dark-green mt-3 p-4 ${
						filter?.selected ? "option-selected txt-white" : ""
					}`}
					style={{
						height: "fit-content",
						lineHeight: "20px",
						borderRadius: "4px",
						backgroundColor: getBackgroundColor(),
					}}
					onClick={handleOptionClick}
				>
					<div className="txt20 bold">{filter?.text}</div>
				</div>
			</div>
		);
	};

	const SelectOption = ({ id }) => {
		let option = options.find((x) => x.id === id);

		const index = filters?.findIndex((x) => x.selected);

		const selected = option?.selected[index];

		function handleOptionClick() {
			if (isCompleted) return;

			let updatedOptions = options.map((x) => {
				if (x.id === id) {
					let updatedSelected = [...x.selected];
					if (selected) updatedSelected[index] = false;
					else updatedSelected[index] = filters[index]?.id;

					return {
						...x,
						selected: updatedSelected,
					};
				} else {
					let updatedSelected = [...x.selected];
					updatedSelected[index] = false;
					return {
						...x,
						selected: updatedSelected,
					};
				}
			});

			setOptions(updatedOptions);
		}

		function getBackgroundColor() {
			return selected && option.correct === selected && isCompleted
				? "#D6D431"
				: selected && option.correct !== selected && isCompleted
				? "#FF5948"
				: selected
				? "#FF7F6B"
				: "#FFFFFF";
		}

		return (
			<div>
				<div
					className={`d-flex align-items-center justify-content-start txt-black mt-3 p-3 ${
						selected ? "option-selected txt-white bold" : ""
					}`}
					style={{
						height: "fit-content",
						lineHeight: "20px",
						borderRadius: "4px",
						backgroundColor: getBackgroundColor(),
					}}
					onClick={handleOptionClick}
				>
					<div className="d-flex align-items-start justify-content-start">
						<div>{option?.id}.</div>
						<div className="ms-2">{option?.text}</div>
					</div>
				</div>
			</div>
		);
	};

	function checkAnswers() {
		const currentFilter = filters.find((x) => x.selected);
		const activeIndex = filters.findIndex((x) => x.selected);

		if (options?.every((x) => !x.selected[activeIndex])) {
			alertError({
				error: " ",
				customMessage: "Para avanzar debes seleccionar una opción.",
			});
			return;
		} else if (
			!options?.some((x) => x.selected[activeIndex] === x.correct)
		) {
			const text = `<div class="text-center">
			<p class="txt-turquoise bold">La recomendación es incorrecta.</p>
			<p class="txt-black">Inténtalo de nuevo</p>
		</div>`;

			modalGenericSmall({
				html: text,
				confirmButtonText: "INTENTAR DE NUEVO",
				allowOutsideClick: true,
			});
		} else if (activeIndex < filters.length - 1) {
			const text = `<div class="text-center">
			<p class="txt-turquoise bold">
				¡Correcto!
			</p>
			<p class="txt-black">
				¡A por la siguiente!
			</p>
		</div>`;

			modalGenericSmall({
				html: text,
				confirmButtonText: "AVANZAR",
				allowOutsideClick: false,
			}).then((res) => {
				if (res.isConfirmed) {
					const updatedFilters = filters.map((filter) => {
						if (filter.id === currentFilter.id) {
							return { ...filter, selected: false };
						} else if (filter.id == currentFilter.id + 1)
							return { ...filter, selected: true };
						else return filter;
					});

					setFilters(updatedFilters);
				}
			});
		} else {
			const text = `<div class="text-center txt14 txt-black">
			<div class="bold txt-turquoise txt18">
				¡Excelente, todas las recomendaciones son correctas!
			</div>
			<div class="mt-3">
				Disminución confirmada de la TFGe desde el inicio:
			</div>
			<div class="mt-3">
				<span class="txt-dark-green bold">≥30 %</span> -> Suspender la administración de voclosporina y reiniciar el tratamiento tras la recuperación de la TFGe con una dosis de 7,9 mg (1 cápsula) 2 veces al día y aumentar la dosis según la tolerabilidad de la función renal.
			</div>
			<div class="mt-3">
				<span class="txt-dark-green bold">>20 % y <30 %</span> -> Reducir la dosis de voclosporina en 7,9 mg (1 cápsula) 2 veces al día. Volver a hacer la prueba en dos semanas; si la disminución de la TFGe no se ha recuperado, volver a reducir la dosis en 7,9 mg (1 cápsula) 2 veces al día.
			</div>
			<div class="mt-3">
				<span class="txt-dark-green bold">≤20 %</span> -> Mantener la dosis actual de voclosporina y supervisar.
			</div>
			<div class="mt-3">
				En ninguno de estos supuestos es necesario suspender definitivamente voclosporina.
			</div>
			<div class="bold txt-light-green mt-4 txt18">
				¡Ahora ya sabes cómo ajustar el tratamiento de Martina!
			</div>
		</div>`;

			modalGeneric({ html: text }).then((res) => {
				if (res.isConfirmed) submitAnswer();
			});
		}
	}

	async function submitAnswer() {
		setIsCompleted(true);

		const penaltyScore = Math.max(points.penalty, MAX_WRONG_ANSWER_POINTS);

		let totalTime = getTimer();
		dispatch(updateTime(totalTime, 1));
		postAnswer({
			scenario: SCENARIO_ID,
			quiz: QUIZ_ID,
			group: participant?._id,
			score: penaltyScore,
			time: time,
			correct: true,
		})
			.then((res) => {
				if (res.status === 201) {
					dispatch(updateScore(penaltyScore, SCORE_TYPES.SCORE));
					dispatch(updateScore(penaltyScore, SCORE_TYPES.PENALTY));

					updateStats({
						...participant,
						time: totalTime,
						score: participant?.score + penaltyScore,
						penalty: participant?.penalty + penaltyScore,
						lastVisited: NAVIGATE_TO,
					});
				}
			})
			.catch((error) => {});
	}

	return (
		<div className="h-100 bg-prueba">
			<div className="container">
				<div className="row h-100">
					<div className="d-flex justify-content-center align-items-start">
						<div className="col-3">
							<div className="txtWhite txt20 bold">
								Disminución confirmada de la TFGe con respecto
								al valor inicial
							</div>
							{filters.map((filter) => {
								return (
									<div>
										<SelectFilterOption id={filter.id} />
									</div>
								);
							})}
							<div className="txt8 mt-5 txt-white">
								Ver ficha técnica de voclosporina. Si la TFGe
								sigue siendo ≥ 60 ml/minuto/1,73 m2 no es
								necesario adoptar medidas.
							</div>
							<div className="txt8 mt-3 txt-white">
								<strong>TFGe:</strong> tasa de filtración
								glomerular estimada
								<br />
								<strong>BID:</strong> dos veces al día
							</div>
						</div>
						<div className="col-1"></div>
						<div className="col-8">
							<div>
								<div className="txtWhite txt20 bold">
									Recomendación de dosificación:{" "}
								</div>
								{options.map((option) => {
									return (
										<div>
											<SelectOption id={option.id} />
										</div>
									);
								})}
							</div>
						</div>
					</div>
				</div>
			</div>
			<div
				className="container"
				style={{
					position: "absolute",
					right: "4vh",
					bottom: "17vh",
					zIndex: 1000,
				}}
			>
				<div className="d-flex justify-content-end">
					{!isCompleted ? (
						<div
							className="btn-main"
							onClick={() => checkAnswers()}
						>
							<div className="text-btn-main">AVANZAR</div>
						</div>
					) : (
						<NextButton navigateTo={NAVIGATE_TO} />
					)}
				</div>
			</div>
			<ScenarioBar activeScenario={scenarioNum}></ScenarioBar>
		</div>
	);
}

export default injectIntl(Scenario6);

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import logo from "../assets/logo-medical.png";
import estrella from "../assets/top/estrella.png";
import { useTimer } from "../hooks/useTimer";
import { updateTime } from "../redux/actions";
import { GAME_TIME_SECS, ROUTES } from "../utils/constants";
import {
	formatMinutes,
	formatScoreTopBar,
	formatSeconds,
	getTimer,
	updateStats,
	updateTimer,
} from "../utils/helpers";
import { modalFichaTecnica, modalText } from "../utils/logger";

const REFERENCIAS = `<div>
    <div>
      <span class="pe-2 bold">1.</span>
      <span class="bold">Rovin BH, Teng YKO, Ginzler EM, et al.</span>
      Efficacy and safety of voclosporin versus placebo for lupus nephritis
      (AURORA 1): a double-blind, randomised, multicentre, placebo-controlled,
      phase 3 trial. Lancet. 2021;397:2070-80.
    </div>
    <div class="mt-3">
      <span class="pe-2 bold">2.</span>
      <span class="bold">Saxena A, Ginzler EM, Gibson K, et al.</span>
      Safety and efficacy of long-Term voclosporin treatment for lupus nephritis
      in the phase 3 AURORA 2 clinical trial. Arthritis Rheumatol.
      2024;76(1):59-67.
    </div>
    <div class="mt-3">
      <span class="pe-2 bold">3.</span>
      <span class="bold">Rojas-Rivera JE, García-Carro C, Ávila A, et al.</span>
      Documento de consenso del Grupo de Estudio de Enfermedades Glomerulares de
      la Sociedad Española de Nefrología (GLOSEN) para el diagnóstico y
      tratamiento de la nefritis lúpica. Nefrología. 2023;43:6-47.
    </div>
  </div>`;

export default function TopBar() {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const participant = useSelector((state) => state?.group);

	const game = useSelector((state) => state?.game) || participant?.game;
	const location = useLocation();

	const score = useSelector((state) => state.group?.score);

	const [time, setTime] = useState(parseInt(participant?.time));

	const [timerActive, setTimerActive] = useState(false);

	const gameDuration = GAME_TIME_SECS;

	useEffect(() => {
		if (!timerActive) setTime(parseInt(participant?.time));
	}, [participant?.time]);

	useTimer(() => {
		if (!timerActive && getTimer()) setTimerActive(true);
		if (timerActive) handleTimer();
	}, 1000);

	const handleTimer = () => {
		if (!getTimer()) setTimerActive(false);
		else {
			if (!participant?.finished) {
				setTime(time + 1);
				updateTimer(time);
			}
		}
	};

	useEffect(() => {
		if (time === gameDuration) {
			if (!participant?.finished) {
				dispatch(updateTime(time));

				updateStats({
					...participant,
					time: time,
					finished: true,
					lastVisited: ROUTES.RESULTS,
				});
				navigate(ROUTES.RESULTS);
			}
		}
	}, [time]);

	return (
		<>
			<div className="py-2 bg-turquoise px-4">
				<div className="d-flex justify-content-between align-items-center txtWhite">
					<div>
						<img src={logo} alt="Medical Challenge" />
					</div>
					<div className="d-flex justify-content-between align-items-center">
						<div className="container-score mx-2 ps-1 pe-2 txt18">
							<img
								src={estrella}
								className="me-2"
								alt="star"
								height={"80%"}
							/>
							{formatScoreTopBar(score)}
						</div>
						<div
							className="roundBtn mx-2 bold d-flex align-items-center justify-content-center"
							onClick={() => modalFichaTecnica()}
						>
							FT
						</div>
						<div
							className="roundBtn mx-2 bold d-flex align-items-center justify-content-center"
							onClick={() => {
								modalText({
									title: "REFERENCIAS BIBLIOGRÁFICAS",
									html: REFERENCIAS,
								});
							}}
						>
							B
						</div>
						<div className="ms-2 timer title1">
							{formatMinutes(
								time,
								gameDuration,
								participant?.finished
							)}
						</div>
						<div className="mx-1 bold blink">:</div>
						<div className="timer title1">
							{formatSeconds(
								time,
								gameDuration,
								participant?.finished
							)}
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
